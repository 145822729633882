export const PromotionTypes = [
    { value: 'Ăn uống' },
    { value: 'Vui chơi' },
    { value: 'Giải trí' },
    { value: 'Mua sắm' }
];

export const PromotionValues = [
    { key: 'percent', value: '%' },
    { key: 'currency', value: 'VND' }
];

export const PromotionPartners = [
    { value: 'Tocotoco' },
    { value: 'HighLands' },
];

export const PromotionBlocks = [
    { value: 'Block A' },
    { value: 'Block B' },
];