import { Injectable } from '@angular/core';
// import { Response } from '@angular/http';

import { ConstantUrl } from '../../shared/constant/url';
import { HttpService } from 'app/shared/services/http.service';
import { BaseService } from 'app/shared/components/base.service';
import { DataService } from 'app/shared/services';
import { Observable } from 'rxjs/Rx';
import { map } from 'rxjs/operators';
import { CategoryModel } from './management-folder.model';
import { CPagination } from 'app/api-models';

@Injectable()
export class ManagementFolderService extends BaseService {
  constructor(
      public service: DataService,
  ) {
      super(service);
      this.domainUrl = ConstantUrl.url_news_domain;
      this.queryUrl = ConstantUrl.url_news_query;
  }

  // Categories
  getAllCategories(params?: any): Observable<CPagination<CategoryModel>> {
    return this.service.get(this.queryUrl + 'category', params).pipe(
      map((res: any) => {
        const pagination = new CPagination<CategoryModel>().decode(res);
        pagination.items = pagination.items.map((item: any, index) => {
          return new CategoryModel(item).createDataForList(); 
        });
        return pagination;
      })
    );
  }
  public getDropdownCategories(params?: any) {
    return this.service.get(this.queryUrl + 'category', params);
  }
  

  getCategoryById(id: string) {
    return this.service.get(this.queryUrl + `category/${id}`);
  }

  createCategory(params?: any) {
    return this.service.post(this.domainUrl + `category`, params);
  }
  updateCategory(params?: any) {
    return this.service.put(this.domainUrl + `category`, params);
  }

  deleteCategory(id: string) {
    return this.service.delete(this.domainUrl + `category/${id}`);
  } 
}
