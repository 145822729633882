export enum GroupStatus {
    ACTIVE = 'ACTIVE',
    DEACTIVE = 'DEACTIVE'
}

export enum GroupCommentStatusEnum {
    'ACTIVE' = 'ACTIVE',
    'DEACTIVE' = 'DEACTIVE'
}

export enum GroupType {
    PUBLIC = 'PUBLIC',          //công khai
    PRIVATE = 'PRIVATE'         //bí mật
}

export enum GroupShowType {
    SHOW = 'SHOW',              //có thể tìm kiếm
    HIDE = 'HIDE'               //ko thể tìm kiếm
}

export enum GroupApprovalType {
    AUTO = 'AUTO',              //ko cần duyệt
    CENSORSHIP = 'CENSORSHIP'   //cần duyệt
}

export enum TypeAllowCommentEnum {
    ON = 'ON', // Cho phép bình luận
    OFF = 'OFF', // Tắt bình luận
    HIDDEN = 'HIDDEN', // Ẩn bình luận
}

export enum PostStatusEnum {
    'ACTIVE' = 'ACTIVE',
    'INACTIVE' = 'INACTIVE',
    'DELETED' = 'DELETED',
    'REJECT' = 'REJECT',
    'WAITING' = 'WAITING',
}
export enum PostRequestStatusEnum {
    'APPROVED' = 'APPROVED',
    'REJECTED' = 'REJECTED',
    'WAITING' = 'WAITING',
    'DRAFT' = 'DRAFT',
}

export enum ActionPostEnum {
    'REJECT' = 'REJECT', // từ chối bài viết
    'APPROVE' = 'APPROVE', // duyệt bài viết
}