export class Role {
  id: number;
  name: string;
  active: boolean = false;
  permissions: any = [];
  createdDate: string;
  constructor(object?) {
    if (object) {
      this.id = object.id;
      this.name = object.name;
      this.active = object.active;
      this.permissions = object.permissions || [];
      this.createdDate = object.createdDate;
    } else {
      this.id = null;
      this.name = '';
      this.active = false;
      this.permissions = [];
    }
  }
  createObjectRequestAPI() {
    return  {
      id: this.id || undefined,
      name: this.name,
      active: this.active,
      permissions: this.permissions
    }
  }
  static getType() {
    return 'role';
  }
}
