import { Injectable } from '@angular/core';
import 'rxjs/add/observable/throw';
import 'rxjs/add/operator/map';
import {map} from 'rxjs/operators';
import {HttpService} from '../../../shared/services/http.service';
import {ConstantUrl} from '../../../shared/constant/url';
import {CPagination} from '../../../api-models';
import {AdminUser} from './admin-user.model';

@Injectable()
export class AdminUserService {
    ConstantUrl = ConstantUrl;
    queryUrl;
    domainUrl;
    constructor(
        public httpService: HttpService,
    ) {
        this.queryUrl = ConstantUrl.url_employee_query + 'employee';
        this.domainUrl = ConstantUrl.url_employee_domain + 'employee';
    }

    getAllUser(params, isPaging = true) {
        const url = this.queryUrl + '/admin/allByQuery';
        if (isPaging) {
            return this.httpService.get(url, params).pipe(
                map(response => {
                    const pagination = new CPagination<AdminUser>().decode(response);
                    pagination.items = pagination.items.map((item, index) => {
                        return new AdminUser(item);
                    });
                    return pagination;
                })
            );
        } else {
            return this.httpService.get(url, params);
        }
    }

    getUserById(id) {
        const url = this.queryUrl + '/' + id;
        return this.httpService.get(url);
    }

    createUser(params) {
        const url = this.domainUrl + '/admin';
        return this.httpService.post(url, params);
    }

    updateUser(params) {
        const url = this.domainUrl + '/admin';
        return this.httpService.put(url, params);
    }

    changeUserActivation(id) {
        const url = this.domainUrl + '/changeActivation/' + id;
        return this.httpService.put(url, {});
    }

    resetPassword(params: any) {
        const url = ConstantUrl.url_sts_domain + 'user/admin/resetPwd';
        return this.httpService.put(url, params);
    }
}
