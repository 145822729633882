import {Injectable} from '@angular/core';
import {CPagination} from 'app/api-models';
import {BaseService} from 'app/shared/components/base.service';
import {ConstantUrl} from 'app/shared/constant/url';
import {DataService} from 'app/shared/services/data.service';
import {HttpService} from 'app/shared/services/http.service';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {SupplierModel} from 'app/shared/models/supplier.model'

@Injectable()

export class SupplierService extends BaseService {
    constructor(
        private _service: DataService,
        private _httpService: HttpService
    ) {
        super(_service);
        this.domainUrl = ConstantUrl.url_utility_domain;
        this.queryUrl = ConstantUrl.url_utility_query;
    }

    getSuppliersList(params): Observable<any> {
        return this._service.get(`${this.queryUrl}provider`, params || {}).pipe(
            map((response: any) => {
                const pagination = new CPagination<SupplierModel>().decode(response);
                pagination.items = pagination.items.map((item: any, index) => {
                    const suppliers = new SupplierModel(item);
                    return suppliers;
                });
                return pagination;
            })
        );
    }
    
    getSupplierById(id:string): Observable<any> {
        return this._service.get(`${this.queryUrl}provider/${id}`,).pipe(
            map((response: any) => {
                return new SupplierModel(response);
            })
        );
    }

    deleteSupplier(id:string) {
        return this._service.delete(`${this.domainUrl}provider/${id}`);
    }

    createSupplier(params:SupplierModel ) {
        return this._service.post(`${this.domainUrl}provider`,params);
    }

    updateSupplier(params:SupplierModel ) {
        return this._service.put(`${this.domainUrl}provider/${params.id}`,params);
    }

    searchEmployeeQuery(params: any) {
        const url = ConstantUrl.url_property_query + 'employee/report/search';
        return this._service.get(url, params);
    }
}