import moment = require('moment');

export class AdminUser {

    id: string;
    code: string;
    email: string;
    phone: string;
    active: boolean;
    createdDate: string;
    pos: any;
    roleCanAssign: any;
    role: any;

    constructor(
        params?: any
    ) {
        Object.assign(this, params);
        this.createdDate = params.createdAt ? moment(params.createdAt).format('DD-MM-YYYY') : '';
    }

    createObjectRequestAPI() {
        let object: any = {};
        this.id && (object.id = this.id);
        object.email = this.email;
        object.phone = this.phone;
        object.active = this.active;
        if (this.pos) {
            object.pos = {
                id: this.pos.id,
                name: this.pos.name,
            };
        }
        object.roleCanAssign = this.roleCanAssign;
        object.roleId = this.role;
        return object;
    }
}
