import { BaseModel } from "../../shared/models/base.model";
import { Address } from "../../shared/models/address.model";
import { POS } from "../orgchart-pos/model/pos.model";
import { ValidateIf, IsNotEmpty, Length, Contains, IsEmail } from "class-validator";
import { Constant } from '../../shared/constant/constant'
import { BaseModelInterface } from 'app/shared/models/base.model.interface';

export enum EStatusTransfer {
  WORKING = 'working',
  DONE = 'done',
  CANCELED = 'canceled',
}

export class TransferUnit extends BaseModel implements BaseModelInterface {
  id: number;
  index: number;
  code: any = '';
  phone: any = '';
  name: any = '';
  identityCode: any = '';
  posBeforeTransfer: any;
  posAfterTransfer: any;
  createdAt: any;
  updatedAt: any;
  status: any;
  hasRemoved: any;
  hasApproved: any;
  constructor(params?) {
    super();
    this.setValue(params);
  }
  setValue(params?) {
    if (params) {
      this.id = params.id || '';
      this.index = params.index || '';
      this.code = params.employeeCode || '';
      this.phone = params.employeePhone || '';
      this.name = params.employeeName || '';
      this.identityCode = params.employeeIdentityCode || '';
      this.createdAt = params.createdAt || '';
      this.updatedAt = params.updatedAt || '';
      this.posBeforeTransfer = params.posBeforeTransfer || {};
      this.posAfterTransfer = params.posAfterTransfer || {};
      this.status = params.status || '';
      this.hasRemoved = params.hasRemoved || '';
      this.hasApproved = params.hasApproved || '';
      super.setValue(params);
    } else {
      this.setAllNull();
      super.setValue(params);
    }

  }
  createDataForList() {
    let object: any = {};
    object.id = this.id;
    object.index = this.index;
    object.code = this.code || '';
    object.phone = this.phone || '';
    object.name = this.name || '';
    object.identityCode = this.identityCode || '';
    object.createdAt = this.createdAt;
    object.updatedAt = this.updatedAt;
    object.posBeforeTransfer = this.posBeforeTransfer || {};
    object.posAfterTransfer = this.posAfterTransfer || {};
    object.status = this.status || '';
    object.hasRemoved = this.hasRemoved || '';
    object.hasApproved = this.hasApproved || '';
    return object;
  }
  setAllNull() {
    this.id = null;
    this.index = null;
    this.code = null;
    this.phone = null;
    this.name = null;
    this.identityCode = null;
    this.createdAt = null;
    this.updatedAt = null;
    this.posBeforeTransfer = null;
    this.posAfterTransfer = null;
    this.status = null;
    this.hasRemoved = null;
    this.hasApproved = null;
  }
  validatePartial() {
    
  }

  validate() {
   
  }
  createObjectRequestAPI() {
   
  }
}
