import { GroupApprovalType, GroupShowType, GroupStatus, GroupType } from 'app/consts/group.const';
import { DateToFormat } from 'app/shared/parse/date-to-format';
import { StringToFormat } from 'app/shared/parse/string-to-date';

export class GroupModel {
    stt: number;
    coverImage: string;
    description: string;
    id: string;
    joinType: boolean;
    lastReactionDate: Date;
    name: string;
    postType: boolean;
    showType: boolean;
    status: boolean;
    totalComments: number;
    totalLikes: number;
    totalMembers: number;
    totalPendingPosts: number;
    totalPosts: number;
    totalShares: number;
    type: string;
    createdDate: string;
    modifiedDate: string;
    projectId: string;
    // rules: string;
    project: any;
    constructor(params?: any) {
        this.coverImage = params ? params.coverImage || null : null;
        this.description = params ? params.description || null : null;
        this.id = params ? params.id || null : null;
        this.joinType = params ? GroupApprovalType.CENSORSHIP === params.joinType : true;
        this.lastReactionDate = params ? params.lastReactionDate || null : null;
        this.name = params ? params.name || null : null;
        this.postType = params ? GroupApprovalType.CENSORSHIP === params.postType : true;
        this.showType = params ? GroupShowType.SHOW === params.showType : true;
        this.status = params ? GroupStatus.ACTIVE === params.status : true;
        this.totalComments = params ? params.totalComments || 0 : 0;
        this.totalLikes = params ? params.totalLikes || 0 : 0;
        this.totalMembers = params ? params.totalMembers || 0 : 0;
        this.totalPendingPosts = params ? params.totalPendingPosts || 0 : 0;
        this.totalPosts = params ? params.totalPosts || 0 : 0;
        this.totalShares = params ? params.totalShares || 0 : 0;
        this.type = params ? params.type || GroupType.PRIVATE : GroupType.PRIVATE;
        this.createdDate = params && params.createdDate ? DateToFormat.parseDate(params.createdDate, 'dd/MM/yyyy - hh:mm:ss') : '';
        this.modifiedDate = params && params.modifiedDate ? DateToFormat.parseDate(params.modifiedDate, 'dd/MM/yyyy - hh:mm:ss') : '';
        this.projectId = params && params.project?  params.project.id : null;
        // this.rules = params ? params.rules || null : null;
    }
}
export class GroupTableModel {
    stt: number;
    coverImage: string;
    description: string;
    id: string;
    joinType: boolean;
    lastReactionDate: Date;
    name: string;
    postType: boolean;
    showType: boolean;
    status: boolean;
    totalComments: number;
    totalLikes: number;
    totalMembers: number;
    totalPendingPosts: number;
    totalPosts: number;
    totalShares: number;
    type: string;
    createdDate: string;
    modifiedDate: string;
    project:any;

    constructor(params?: any) {
        this.coverImage = params ? params.coverImage || null : null;
        this.description = params ? params.description || null : null;
        this.project = params ? params.project || {} : {}
        this.id = params ? params.id || null : null;
        this.joinType = params ? GroupApprovalType.CENSORSHIP === params.joinType : true;
        this.lastReactionDate = params ? params.lastReactionDate || null : null;
        this.name = params ? params.name || null : null;
        this.postType = params ? GroupApprovalType.CENSORSHIP === params.postType : true;
        this.showType = params ? GroupShowType.SHOW === params.showType : true;
        this.status = params ? params.status : null;
        this.totalComments = params ? params.totalComments || 0 : 0;
        this.totalLikes = params ? params.totalLikes || 0 : 0;
        this.totalMembers = params ? params.totalMembers || 0 : 0;
        this.totalPendingPosts = params ? params.totalPendingPosts || 0 : 0;
        this.totalPosts = params ? params.totalPosts || 0 : 0;
        this.totalShares = params ? params.totalShares || 0 : 0;
        this.type = params ? params.type || GroupType.PRIVATE : GroupType.PRIVATE;
        this.createdDate = params && params.createdDate ? DateToFormat.parseDate(params.createdDate, 'dd/MM/yyyy - hh:mm:ss') : '';
        this.modifiedDate = params && params.modifiedDate ? DateToFormat.parseDate(params.modifiedDate, 'dd/MM/yyyy - hh:mm:ss') : '';
    }
}

export class GroupReportModel {
    stt: number;
    employeeId: string; // ID nhân viên
    employeeName: string; // Họ tên
    employeePhone: string; // SĐT
    employeeEmail: string; // Email
    employeePos: { id: string; name: string }; // Zone/POS
    totalGroup: number; // Số nhóm tham gia
    totalGroupPost: number; // Số bài đã viết trong tất cả các nhóm
    totalGroupComment: number; // Số bình luận trong tất cả các nhóm
    totalShare: number; // Lượt share trong tất cả các nhóm
    totalLike: number; // Lượt like trong tất cả các nhóm
    totalDeals: number; // Tổng thỏa thuận
    totalYCDCH: number; // Tổng YCDCH đã tạo
    totalYCDC: number; // Tổng YCDCO đã tạo
    totalComplete: number; // Tổng số giao dịch đã hoàn tất
    totalInvited: number; // Tổng số TVV đã giới thiệu
    interactDate: Date; // Thời gian tương tác cuối cùng

    constructor(params?: any) {
        this.employeeId = params ? params.employeeId || null : null;
        this.employeeName = params ? params.employeeName || null : null;
        this.employeePhone = params ? params.employeePhone || null : null;
        this.employeeEmail = params ? params.employeeEmail || null : null;
        this.employeePos = params ? params.employeePos || null : null;
        this.totalGroup = params ? params.totalGroup || 0 : 0;
        this.totalGroupPost = params ? params.totalGroupPost || 0 : 0;
        this.totalGroupComment = params ? params.totalGroupComment || 0 : 0;
        this.totalShare = params ? params.totalShare || 0 : 0;
        this.totalLike = params ? params.totalLike || 0 : 0;
        this.totalDeals = params ? params.totalDeals || 0 : 0;
        this.totalYCDCH = params ? params.totalYCDCH || 0 : 0;
        this.totalYCDC = params ? params.totalYCDC || 0 : 0;
        this.totalComplete = params ? params.totalComplete || 0 : 0;
        this.totalInvited = params ? params.totalInvited || 0 : 0;
        this.interactDate = params && params.interactDate ? DateToFormat.parseDate(params.interactDate, 'dd/MM/yyyy - hh:mm:ss') : '';
    }
}

export class GroupProductModel {
    stt: number;
    name: string;
    status: boolean;

    constructor(params?: any) {
        Object.assign(this, {

        }, params)
    }
}

export class CategoryModel {
    stt: number;
    name: string;
    active: boolean;
    image: any;
    project: any;

    constructor(params?: any) {
        Object.assign(this, {

        }, params)
    }
}