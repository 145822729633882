import { GeoLocation } from 'app/pages/property/location.model';
import { FileModel } from 'app/shared/components/file-uploader';

export class SupplierModel {
    id: string;
    active: boolean;
    avatar: any;
    banner: any;
    businessHours: any;
    contract: FileModel[];
    createdDate: string;
    description: string;
    email: string;
    fax: string;
    location: GeoLocation;
    locationAddress: string;
    name: string;
    phone: string;
    softDelete: boolean;
    tax: string;
    type: string;
    website: string;
    code: string;
    country: string | undefined;
    province: string | undefined;
    district: string | undefined;
    ward: string | undefined;
    street: string;
    time: string;
    // status: boolean;
    representative: string;
    address: any;
    addressView: string; 
    enabled: boolean;
    good: boolean;
    employees: any[];
    constructor(params?: any) {
        this.setValue(params);
    }

    setValue(params: any) {
        if (params) {
            this.id = params.id || '';
            this.active = params.active || false;
            this.avatar = params.avatar || {};
            this.businessHours = params.businessHours || {};
            this.contract = params.contract || [];
            this.createdDate = params.createdDate || null;
            this.description = params.description || '';
            this.email = params.email || '';
            this.fax = params.fax || '';
            this.location = new GeoLocation(params.location) || new GeoLocation();
            this.locationAddress = params.locationAddress;
            this.name = params.name || '';
            this.phone = params.phone || '';
            this.softDelete = params.softDelete || false;
            this.tax = params.tax || '';
            this.type = params.type || '';
            this.website = params.website || '';
            this.code = params.code || '';
            if(params.address) {
                this.country =  params.address.country || undefined;
                this.province =  params.address.province || undefined
                this.district =  params.address.district || undefined
                this.ward = params.address.ward || undefined;
                this.street = params.address.street || '';
                this.addressView =  [
                    params.address.street,
                    params.address.ward ,
                    params.address.district,
                    params.address.province,
                    params.address.country, 
                ].filter((item) => item).join(', ');
            }
           
            this.time = params.time || '';
            // this.status = params.status || false;
            this.representative = params.representative || '';
            this.address = params.address || {};
            this.banner = params.banner || {};
            this.enabled = params.enabled || false
            this.good = params.good || false;
            this.employees = params.employees || [];
        } else {
            this.id = '';
            this.active = false;
            this.avatar = {};
            this.businessHours = {};
            this.contract = [];
            this.createdDate = null;
            this.description = '';
            this.email = '';
            this.fax = '';
            this.location = new GeoLocation();
            this.locationAddress = null;
            this.name = '';
            this.phone = '';
            this.softDelete = false;
            this.tax = '';
            this.type = '';
            this.website = '';
            this.code = '';
            this.country = undefined;
            this.province = undefined
            this.district = undefined
            this.ward = undefined;
            this.street = '';
            this.time = '';
            // this.status = false;
            this.representative = '';
            this.address = {},
            this.banner = {},
            this.addressView = '';
            this.enabled = false
            this.good = false
            this.employees = [];
        }
    }
}