export class AppSearchModel {
  key: string;
  name: string;
  selectedValue: any[] = [];
  selectedValueApplied: any[] = [];
  options: any[] = [];
  queryParam: string = '';

  constructor(init?: Partial<AppSearchModel>) {
    Object.assign(this, init);
  }
}