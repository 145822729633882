import { NotificationTemplateChannel } from "../enum/notification-manual-template.enum";

export class NotificationTemplateConstant {

    public static NotificationManualChannel = [
        { value: NotificationTemplateChannel.EMAIL },
        { value: NotificationTemplateChannel.SMS },
        { value: NotificationTemplateChannel.NOTIFICATION }
    ];

    public static NotificationChanels: any = [
        { name: "Email", _id: 'Email' },
        { name: "Pushnotification", _id: 'Notification' },
        { name: "SMS", _id: 'SMS' },
    ];

    public static NoticeStatus: any = [
        { name: "Đã gửi", code: 'SENT' },
        { name: "Chờ gửi", code: 'UNSENT' },
    ];

    public static BrandNames: any = [
        { name: "PropHub", value: '' },
        { name: "PropCare", value: 'CarePlus' },
    ];


    public static BrandNameDxhomes: any = [
        { name: "DXAgent ", value: 'DXAGENT' },
        { name: "DXHomes", value: 'DATXANH' },
    ];

    public static notiBrandNames: any = [
        { name: "PropHub", value: 'RA' },
        { name: "PropCare", value: 'CP' },
    ];

    public static notiBrandNameDxhomes: any = [
        { name: "DXAgent", value: 'RA' },
        { name: "DXHomes", value: 'CP' },
    ];

}

