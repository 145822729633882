import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { StorageService } from 'app/shared/services';

export enum EProfileAction {
  Personal = 'thong-tin-tai-khoan',
  ChangePassword = 'doi-mat-khau',
  ChangePhone = 'doi-so-dien-thoai',
  ChangeEmail = 'doi-email',
  SocialUrl = 'social-url',
  UserManual = 'user-manual-profile',
  Invite = 'moi-ban-be'
}

@Component({
  selector: 'app-user-action-card',
  templateUrl: './user-action-card.component.html',
  styleUrls: ['./user-action-card.component.scss']
})
export class UserActionCardComponent implements OnInit {

  @Input() actionId: EProfileAction = EProfileAction.Personal;
  @Output() actionIdChange: EventEmitter<EProfileAction> = new EventEmitter();
  ProfileAction = EProfileAction;
  isTrialRegister: boolean = false;

  public actions = [
    { id: EProfileAction.Personal, name: 'Thông tin tài khoản', activeIcon: '/assets/img/profile/account-active.png', noActiveIcon: '/assets/img/profile/account.png' },
    { id: EProfileAction.ChangePassword, name: 'Đổi mật khẩu', activeIcon: '/assets/img/profile/password-active.png', noActiveIcon: '/assets/img/profile/password.png' },
    // { id: EProfileAction.ChangePhone, name: 'Thay đổi số điện thoại', activeIcon: '/assets/img/profile/phone-blue.svg', noActiveIcon: '/assets/img/profile/phone.svg' },
    // { id: EProfileAction.ChangeEmail, name: 'Thay đổi email', activeIcon: '/assets/img/profile/email-blue.svg', noActiveIcon: '/assets/img/profile/email.svg' },
    { id: EProfileAction.SocialUrl, name: 'Mạng xã hội', activeIcon: '/assets/img/profile/social-active.png', noActiveIcon: '/assets/img/profile/social.png' },
    // { id: EProfileAction.UserManual, name: 'Hướng dẫn cập nhật tài khoản', activeIcon: '/assets/img/profile/guide-active.png', noActiveIcon: '/assets/img/profile/guide.png' },
    { id: EProfileAction.Invite, name: 'Giới thiệu bạn bè', activeIcon: '/assets/img/profile/invite-active.png', noActiveIcon: '/assets/img/profile/invite.png'}
  ];

  constructor(
    private storage: StorageService,
  ) { }

  ngOnInit() {
    this.isTrialRegister = this.storage.retrieve('isTrialRegister');
  }

  onChangeAction(actionId: EProfileAction) {
  if (this.isTrialRegister && actionId !== EProfileAction.ChangePassword) {
      return;
    }
    this.actionId = actionId;
    this.actionIdChange.emit(this.actionId);
  }
}
