import { Constant } from '../constant/constant';
import { IPos } from '../interfaces/commission-transaction.interface';
import { ICommissionPeriod } from '../interfaces/commission.interface';
import { DateToFormat } from '../parse/date-to-format';
import { PosModel } from './commission-transaction.model';

export class CommissionPeriodModel implements ICommissionPeriod {
    id: string;
    type: string;
    name: string;
    code: string;
    pos: IPos;
    isPublish: boolean;
    createdDate: string;

    statusString: string;

    constructor(params?: any) {
        params = params || {};
        const period = Constant.LIST_PERIOD_COMM.find(x => x.id === params.name);

        this.id = params.id || '';
        this.name = period ? period.name : '';
        this.type = params.type || '';
        this.code = params.code || '';
        this.pos = new PosModel(params.pos);
        this.isPublish = params.isPublish || false;
        this.createdDate = params.createdDate ? DateToFormat.parseDate(params.createdDate, 'dd/mm/yyyy hh:mm') : '';

        this.statusString = this.isPublish ? 'Đã công bố' : 'Chưa công bố';
    }
}