import { Injectable } from '@angular/core';
import { DataService } from '../../shared/services/data.service';
import { ConstantUrl } from '../../shared/constant/url';
import { BaseService } from '../../shared/components/base.service';
import 'rxjs/add/observable/throw';
import 'rxjs/add/operator/map';
import {HttpService} from '../../shared/services/http.service';
import {map} from 'rxjs/operators';
import {CPagination} from '../../api-models';
import {OrgChartNew} from './orgchart-new.model';


@Injectable()
export class OrgChartService extends BaseService {
    ConstantUrl = ConstantUrl;
    constructor(
        public service: DataService,
        public httpService: HttpService,
    ) {
        super(service);
        this.domainUrl = ConstantUrl.url_orgchart_domain + 'orgchart';
        this.queryUrl = ConstantUrl.url_orgchart_query + 'orgchart';
    }

    getAllOrgchart(params, isPaging = true) {
        const url = this.queryUrl + '/allByQuery';
        if (isPaging) {
            return this.httpService.get(url, params).pipe(
                map(response => {
                    const pagination = new CPagination<OrgChartNew>().decode(response);
                    pagination.items = pagination.items.map((item, index) => {
                        return new OrgChartNew(item);
                    });
                    return pagination;
                })
            );
        } else {
            return this.httpService.get(url, params);
        }
    }


    createOrg(params) {
        const url = this.domainUrl;
        return this.httpService.post(url, params);
    }

    updateOrg(params) {
        const url = this.domainUrl;
        return this.httpService.put(url, params);
    }


    changeOrgActivation(id) {
        const url = this.domainUrl + '/changeActivation/' + id;
        return this.httpService.put(url, {});
    }

    getDataPrepareForOrgChart(id?: string, params = {}) {
        const url = id ? this.queryUrl + '/prepare/' + id : this.queryUrl + '/prepare';
        return this.httpService.get(url, params);
    }

    getAllByUser() {
        const url = this.queryUrl + '/allByUser';
        return this.httpService.get(url);
    }


}
