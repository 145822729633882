import moment = require('moment');

export class OrgChartNew {

    id: string;
    name: string;
    childrens: any;
    status: string;
    active: boolean;
    createdDate: string;
    modifiedDate: string;
    createdBy: string;
    modifiedBy: string;
    isExpand: boolean;

    constructor(
        params?: any
    ) {
        Object.assign(this, params);
        this.createdDate = params && params.createdDate ? moment(params.createdDate).format('DD-MM-YYYY') : '';
        this.modifiedDate = params && params.modifiedDate ? moment(params.modifiedDate).format('DD-MM-YYYY') : '';
    }
}
