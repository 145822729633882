export class HistoryFilterConstant {
    public static employee = 'employee';
    public static group = 'group';
    public static groupMember = 'groupMember';
    public static groupPost = 'groupPost';
    public static event = 'event';
    public static social = 'social';
    public static news = 'news';
    public static campaign = 'campaign';
    public static cafe = 'cafe';
    public static compare = 'compare';
    public static salesProgram = 'salesProgram';
}
