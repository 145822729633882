import { Constant, NewsStatus } from "app/shared/constant/constant";
import { DateToFormat } from "app/shared/parse/date-to-format";

export class CategoryModel  {
    id: String;
    code: String;
    name: String;
    status: boolean;

    constructor(params?: any) {
        params = params || {};
        this.id = params.id || '';
        this.code = params.code || '';
        this.name = params.name || '';
        this.status = params.status === NewsStatus.ACTIVE;
    }

    createDataForList() {
        let object: any = Object.assign({}, this);
        return object;
      }
    createObjectRequestAPI(data) {
        let object: any = Object.assign({}, this, data);
        return object;
    }
}
